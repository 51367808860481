import dayjs from 'dayjs'
import Link from 'next/link'
import cx from 'classnames'

import Container from './Container'
import Logo from './Logo'
import { useContext } from 'react'
import { SettingsContext } from '../context/settings'

function Footer() {
  const settings = useContext(SettingsContext)
  const footerMenuItems = settings?.menus.footer
  const legalMenuItems = settings?.menus.legal

  return (
    <footer className="footer">
      <Container>
        <Logo color="white" />
        {footerMenuItems?.length && (
          <div className="footer-menu">
            {footerMenuItems?.map((item) => (
              <Link href={item.url} key={item.title}>
                <a target={item.target} className="footer-menu-item">
                  {item.title}
                </a>
              </Link>
            ))}
          </div>
        )}

        <div className="footer-copyright">
          <span className="footer-copyright-item">
            Copyright © {dayjs().format('YYYY')}
          </span>
          {legalMenuItems?.map((item) => (
            <Link href={item.url} key={item.title}>
              <a
                target={item.target}
                className={cx('footer-copyright-item', 'footer-copyright-link')}
              >
                {item.title}
              </a>
            </Link>
          ))}
        </div>
      </Container>
      <style jsx>{`
        .footer {
          @apply bg-black pt-[100px] pb-[40px] text-white;
        }
        .footer-menu {
          @apply mt-[30px] grid grid-cols-2 items-center justify-center gap-y-4 text-center sm:flex;
        }
        .footer-menu-item {
          @apply px-4 font-ibm text-[15px] font-medium leading-[19.5px] tracking-[0.3px];
        }
        .footer-copyright {
          @apply mt-[100px] flex flex-col items-center justify-center gap-y-2 sm:flex-row;
          @apply font-inter text-[10px] font-normal leading-[22px];
        }

        .footer-copyright-item {
          font-size: 12px;
          line-height: 22px;
        }

        .footer-copyright-item + .footer-copyright-item {
          @apply sm:ml-[20px];
        }
        .footer-copyright-link {
          @apply underline;
        }
      `}</style>
    </footer>
  )
}

export default Footer
